const theme = {
  blue: "#155be8",
  white: "#ffffff",
  black: "#000000",
  gray1: "#cccccc",
  gray2: "#e6e6e6",
  BREAKPOINT: 600,
};

export default theme;
